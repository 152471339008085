import React, { useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import FormLabel from '@material-ui/core/FormLabel';
import StyledFormControl from '../StyledFormControl';
import { BasicConfigInputsInputTypeEnum, BasicConfigParams } from '../../../../../api';
import { uploadImages } from '../../../../../utilities/WebApi';
import styled from '@emotion/styled/macro';
import ImageCard from './ImageCard';
import { isNumber } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { WeeklyFormInputProps } from '../WeeklyFormInput';
import { IconButton } from '@material-ui/core';

export interface ImageInputProps extends WeeklyFormInputProps {
  inputType: BasicConfigInputsInputTypeEnum.Image;
  params: { many?: boolean } & BasicConfigParams;
}

export interface ImageObject {
  name: string;
  description: string;
}

const HiddenInput = styled.input`
  display: none;
`;

const ImageInputContainer = styled.div``;

const IMAGE_MAX_SIZE = 10 * 1024 * 1024; // 10 MB

const MaterialInput: React.FunctionComponent<FieldProps & ImageInputProps> = ({
  field,
  form: { setFieldValue },
  label,
  required,
  onValueChange,
  lockField,
  locked
}) => {
  const { addToast } = useToasts();

  const upload = useCallback(
    async (files: FileList | null, editIndex?: number) => {
      const checkImageSizeLimit = (file: File): Boolean => {
        if (file.size > IMAGE_MAX_SIZE) {
          addToast(`Error: ${file.name} is bigger than ${IMAGE_MAX_SIZE / (1024 * 1024)} MB !`, {
            appearance: 'error',
            autoDismiss: true
          });
          return false;
        }
        return true;
      };

      try {
        if (!files || files.length === 0) {
          return;
        }

        const imgs = Array.from(files).filter(checkImageSizeLimit);
        const { images } = await uploadImages(imgs);
        const newValue = [...field.value];
        if (isNumber(editIndex) && editIndex > -1) {
          newValue[editIndex].name = images && images.length ? (images[0] as ImageObject).name : '';
          setFieldValue(field.name, newValue);
          onValueChange(field.name, newValue);
        } else {
          if (images && images.length) {
            images.forEach((image: object): void => {
              newValue.push({
                name: 'name' in image ? (image as ImageObject).name : '',
                description: ''
              });
            });
            setFieldValue(field.name, newValue);
            onValueChange(field.name, newValue);
          }
        }
      } catch (e) {
        console.error('failed', e);
      }
    },
    [setFieldValue, field, onValueChange, addToast]
  );

  const deleteImg = useCallback(
    async (name: string) => {
      try {
        // For now we will rely on the image being auto-removed from the buckets.
        // The problem is that this will delete the images but not delete the weekly data since it's
        // only deleted once it's saved.
        // const { deleted } = await deleteImage(name);
        const newValue: ImageObject[] = field.value.reduce(
          (acc: ImageObject[], item: ImageObject): ImageObject[] => {
            if (item.name.indexOf(name || '') === -1) {
              acc.push(item);
            }
            return acc;
          },
          []
        );
        setFieldValue(field.name, newValue);
        onValueChange(field.name, newValue);
      } catch (e) {
        console.error('failed', e);
      }
    },
    [setFieldValue, field, onValueChange]
  );

  return (
    <ImageInputContainer>
      <StyledFormControl>
        <FormLabel required={required}>{label}</FormLabel>
        <HiddenInput
          id="imageUpload"
          accept="image/*"
          multiple
          type="file"
          name="images"
          disabled={locked}
          onClick={() => {
            lockField(field.name, true);
          }}
          onChange={e => {
            upload(e.currentTarget.files);
            lockField(field.name, false);
          }}
          value=""
        />
        <label htmlFor="imageUpload">
          <IconButton color="primary" aria-label="upload image" component="span" disabled={locked}>
            <AddIcon />
          </IconButton>
        </label>
      </StyledFormControl>
      {field.value.length
        ? field.value.map((image: ImageObject, index: number) => (
            <ImageCard
              key={index}
              index={index}
              data={image}
              onLock={setLock => {
                lockField(field.name, setLock);
              }}
              disabled={locked}
              onDelete={deleteImg}
              onEdit={upload}
              onChangeText={(value: string) => {
                const newValue = [...field.value];
                newValue[index].description = value;
                setFieldValue(field.name, newValue);
                onValueChange(field.name, newValue);
              }}
            />
          ))
        : null}
    </ImageInputContainer>
  );
};

const ImageInput: React.FunctionComponent<ImageInputProps> = inputProps => {
  return (
    <Field name={inputProps.variableName}>
      {(props: FieldProps) => <MaterialInput {...props} {...inputProps} />}
    </Field>
  );
};

export default ImageInput;
