import styled from '@emotion/styled/macro';
import Card, { CardProps } from '@material-ui/core/Card';

export interface StyledCardProps extends CardProps {
  disabled: boolean;
}

const StyledCard = styled(Card)<StyledCardProps>`
  width: 100%;
  display: flex;
  padding: 0.8rem 2.3rem 0.8rem 0.8rem;
  justify-content: flex-start;
  align-items: top;
  position: relative;
  margin-bottom: 0.5rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export default StyledCard;
