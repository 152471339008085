import React from 'react';
import styled from '@emotion/styled/macro';
import { CircularProgress } from '@material-ui/core';

interface WeeklyPreviewProps {
  previewHtml: string | null;
  loading: boolean;
}

const Preview = styled.div`
  display: flex;
  align-items: top;
  justify-content: flex-start;
  overflow: scroll;
  padding: 0.5rem;

  position: sticky;
  top: 0;
  z-index: 100;
`;

const A4 = styled.div`
  // Height = A4 height + padding-top + padding-bottom + a little extra to show that there are more pages
  height: calc(842px + 2rem + 2rem);
  min-width: calc(595px + 2rem);
  overflow: hidden;
  padding: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Iframe = styled.iframe`
  padding: 0;
  border: none;
  height: 100%;
  width: 100%;
`;

const PreviewInfoMessage = styled.div`
  font-size: 16px;
  text-align: center;
`;

const Bolded = styled(PreviewInfoMessage)`
  font-weight: bold;
`;

const WeeklyPreview: React.FunctionComponent<WeeklyPreviewProps> = ({ previewHtml, loading }) => {
  return (
    <Preview>
      <A4>
        {loading && <CircularProgress />}
        {!loading && previewHtml && <Iframe title="Weekly Preview" srcDoc={previewHtml} />}
        {!loading && !previewHtml && (
          <PreviewInfoMessage>
            <Bolded>Nothing to show yet!</Bolded>
            <div>Please edit the weekly to show preview.</div>
          </PreviewInfoMessage>
        )}
      </A4>
    </Preview>
  );
};

export default WeeklyPreview;
