import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ImageObject } from '.';
import EditDialog from './EditDialog';
import HoverableContainer, { HoverableContainerRenderProps } from './HoverableContainer';
import LabelType from '../LabelType';
import { getBasePath } from '../../../../../utilities/WebApi';
import StyledCard from '../StyledCard';
import StyledIconButton from '../StyledIconButton';

interface ImageCardProps {
  data: ImageObject;
  index: number;
  onDelete: (filename: string) => void;
  onEdit: (file: FileList, editIndex: number) => void;
  onChangeText: (newText: string) => void;
  onLock: (locked: boolean) => void;
  disabled: boolean;
}

const Image = styled.div<{ url: string }>`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  background-color: #cecdcd;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  cursor: pointer;
`;

const Cover = styled.div`
  background-color: black;
  color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTextField = styled(TextField)<TextFieldProps>`
  width: 100%;
`;

const getFileName = (url: string): string | undefined => url.split('/').pop();

const ImageCard: React.FunctionComponent<ImageCardProps> = ({
  data,
  onDelete,
  onEdit,
  onLock,
  onChangeText,
  index,
  disabled
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(data.description);
  }, [data.description, setValue]);

  return (
    <StyledCard disabled={disabled}>
      <HoverableContainer>
        {({ targetClass }: HoverableContainerRenderProps) => (
          <>
            <Cover
              className={targetClass}
              onClick={() => {
                setEdit(true);
                onLock(true);
              }}
            >
              <VisibilityIcon />
            </Cover>
            <Image url={`${getBasePath()}/images/${data.name}`} />
          </>
        )}
      </HoverableContainer>
      <StyledTextField
        label={
          <>
            <span>Description</span>
            <LabelType>(text)</LabelType>
          </>
        }
        disabled={disabled}
        value={value}
        multiline
        onFocus={() => {
          onLock(true);
        }}
        onChange={e => {
          const { value } = e.target;
          setValue(value);
        }}
        onBlur={e => {
          const { value } = e.target;
          onChangeText(value);
          onLock(false);
        }}
      />
      <StyledIconButton
        aria-label="delete image"
        onClick={() => onDelete(getFileName(data.name) || '')}
        size="small"
        disabled={disabled}
      >
        <DeleteIcon fontSize="small" />
      </StyledIconButton>
      <EditDialog
        isOpen={edit}
        onClose={() => {
          setEdit(!edit);
          onLock(false);
        }}
        onDone={(file: FileList) => {
          onEdit(file, index);
          setEdit(false);
          onLock(false);
        }}
        data={data}
      />
    </StyledCard>
  );
};

export default ImageCard;
