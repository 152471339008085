import styled from '@emotion/styled/macro';
import FormControl from '@material-ui/core/FormControl';

const StyledFormControl = styled(FormControl)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export default StyledFormControl;
