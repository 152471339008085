import React, { useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import FormLabel from '@material-ui/core/FormLabel';
import StyledFormControl from '../StyledFormControl';
import { BasicConfigInputsInputTypeEnum, BasicConfigParams } from '../../../../../api';
import { WeeklyFormInputProps } from '../WeeklyFormInput';
import styled from '@emotion/styled/macro';
import EventCard from './EventCard';

export interface EventInputProps extends WeeklyFormInputProps {
  inputType: BasicConfigInputsInputTypeEnum.Event;
  params: { many?: boolean } & BasicConfigParams;
}

export interface EventObject {
  date: string;
  time: string;
  description: string;
}

const EventInputContainer = styled.div``;

const MaterialInput: React.FunctionComponent<FieldProps & EventInputProps> = ({
  field,
  form: { setFieldValue },
  label,
  required,
  onValueChange,
  lockField,
  locked
}) => {
  const handleDeleteEvent = useCallback(
    (index: number) => {
      const newValue: EventObject[] = [...field.value];
      newValue.splice(index, 1);
      setFieldValue(field.name, newValue);
      onValueChange(field.name, newValue);
    },
    [setFieldValue, field, onValueChange]
  );

  const handleAddEvent = useCallback(() => {
    const newValue: EventObject[] = [...field.value];
    newValue.push({ date: '', time: '', description: '' });
    setFieldValue(field.name, newValue);
    onValueChange(field.name, newValue);
  }, [setFieldValue, field, onValueChange]);

  const handleChangeEvent = useCallback(
    (event: EventObject, index: number) => {
      if (
        field.value[index].date === event.date &&
        field.value[index].time === event.time &&
        field.value[index].description === event.description
      ) {
        return;
      }
      const newValue: EventObject[] = [...field.value];
      newValue[index].date = event.date;
      newValue[index].time = event.time;
      newValue[index].description = event.description;
      setFieldValue(field.name, newValue);
      onValueChange(field.name, newValue);
    },
    [setFieldValue, field, onValueChange]
  );

  return (
    <EventInputContainer>
      <StyledFormControl>
        <FormLabel required={required}>{label}</FormLabel>
        <IconButton
          color="primary"
          aria-label="add event"
          component="span"
          onClick={handleAddEvent}
        >
          <AddIcon />
        </IconButton>
      </StyledFormControl>
      {field.value.length
        ? field.value.map((event: EventObject, index: number) => (
            <EventCard
              key={index}
              index={index}
              data={event}
              onEventDelete={(i: number) => {
                handleDeleteEvent(i);
              }}
              onEventChange={(e: EventObject, i: number) => {
                handleChangeEvent(e, i);
              }}
              onLock={setLock => {
                lockField(field.name, setLock);
              }}
              disabled={locked}
            />
          ))
        : null}
    </EventInputContainer>
  );
};

const EventInput: React.FunctionComponent<EventInputProps> = inputProps => {
  return (
    <Field name={inputProps.variableName}>
      {(props: FieldProps) => <MaterialInput {...props} {...inputProps} />}
    </Field>
  );
};

export default EventInput;
