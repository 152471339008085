import React from 'react';
import styled from '@emotion/styled/macro';

export interface HoverableContainerRenderProps {
  targetClass: string;
}

const hoverableClass = 'hoverable__target';

const Container = styled.div`
  dispay: inline-block;
  cursor: pointer;
  position: relative;

  .${hoverableClass} {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    .${hoverableClass} {
      opacity: 0.4;
      transition: opacity 0.2s ease-in-out;
    }
  }
`;

const HoverableContainer: React.FunctionComponent = ({ children }) => {
  return (
    <Container>
      {typeof children === 'function'
        ? children({ targetClass: hoverableClass } as HoverableContainerRenderProps)
        : children}
    </Container>
  );
};

export default HoverableContainer;
