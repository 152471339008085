import React from 'react';
import styled from '@emotion/styled/macro';
import boids from './boids.png';
import { FunctionComponent } from 'react';

const Boids = styled.img`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 18rem;
  z-index: -1;
`;

const BoidsImg: FunctionComponent = () => <Boids src={boids} alt="boids" />;

export default BoidsImg;
