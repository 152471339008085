import React from 'react';

import BasicButton from './BasicButton';
import styled from '@emotion/styled/macro';

const SendButton = styled(BasicButton)`
  background-color: #c48282;

  &:hover {
    background-color: #8b4141;
  }
`;

interface SubmitButtonProps {
  onSubmit: () => Promise<void>;
  disabled: boolean;
}

const SubmitButton: React.FunctionComponent<SubmitButtonProps> = ({ onSubmit, disabled }) => {
  return (
    <SendButton onClick={onSubmit} color="secondary" variant="contained" disabled={disabled}>
      Send
    </SendButton>
  );
};

export default SubmitButton;
