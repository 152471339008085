import React from 'react';
import { Field, FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import styled from '@emotion/styled/macro';

import { BasicConfigInputsInputTypeEnum } from '../../../../api/api';
import { validateRequired } from '../../utilities/formUtils';
import LabelType from './LabelType';
import { WeeklyFormInputProps } from './WeeklyFormInput';

export interface NumberInputProps extends WeeklyFormInputProps {
  inputType: BasicConfigInputsInputTypeEnum.Number;
}

// Placeholder - just an example of how to combine MUI + emotion + Formik
const StyledField = styled(TextField)<TextFieldProps>`
  // Remove arrows
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  width: 220px;
`;

const MaterialInput: React.FunctionComponent<FieldProps & NumberInputProps> = ({
  field,
  form: { errors, touched },
  label,
  required,
  onValueChange,
  lockField,
  locked
}) => {
  const error = errors[field.name];
  const isTouched = touched[field.name];

  return (
    <StyledField
      {...field}
      onFocus={() => {
        lockField(field.name, true);
      }}
      onBlur={e => {
        lockField(field.name, false);
        field.onBlur(e);
      }}
      onChange={e => {
        const value = parseInt(e.target.value, 10);
        onValueChange(field.name, value);
        field.onChange(e);
      }}
      required={required}
      disabled={locked}
      type="number"
      error={isTouched && !!error}
      helperText={isTouched ? error : ''}
      label={
        <span data-testid={label}>
          {label}
          <LabelType>(number)</LabelType>
        </span>
      }
    />
  );
};

const NumberInput: React.FunctionComponent<NumberInputProps> = ({
  variableName,
  ...inputProps
}) => {
  return (
    <Field
      name={variableName}
      component={MaterialInput}
      validate={inputProps.required ? validateRequired : () => null}
      {...inputProps}
    ></Field>
  );
};

export default NumberInput;
