import React, { useState, useCallback, useRef } from 'react';
import { FieldProps, Field } from 'formik';
import moment from 'moment';
import 'moment/locale/sv';
import styled from '@emotion/styled/macro';

import { BasicConfigInputsInputTypeEnum } from '../../../../../api';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Chip from './Chip';
import ChipLabel from './ChipLabel';
import { WeeklyFormInputProps } from '../WeeklyFormInput';

export interface WeekListInputProps extends WeeklyFormInputProps {
  inputType: BasicConfigInputsInputTypeEnum.Weeklist;
}

interface WeekListInputState {
  inputValue: string;
  editIndex: number;
}

interface WeekListChip {
  week: number;
  text: string;
}

enum KEYS {
  ENTER = 'Enter',
  TAB = 'Tab',
  COMMA = ','
}

const allowedAddKeys: string[] = [KEYS.ENTER, KEYS.COMMA, KEYS.TAB];

const StyledInput = styled(TextField)<TextFieldProps>`
  width: 100%;
`;

const ChipContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const ChipPrinter = (chip: WeekListChip) => chip.text;

const currentWeek: number = moment()
  .locale('sv')
  .week();
const MaterialInput: React.FunctionComponent<FieldProps & WeekListInputProps> = ({
  form: { errors, touched, setFieldValue, setFieldTouched },
  field,
  label,
  required,
  onValueChange,
  lockField,
  locked
}) => {
  const [state, setState] = useState<WeekListInputState>({
    inputValue: '',
    editIndex: -1
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChipAdd = useCallback(
    (e: React.KeyboardEvent) => {
      if (allowedAddKeys.indexOf(e.key) === -1) {
        return;
      }

      const chip = (e.target as HTMLInputElement).value;

      if (state.editIndex === -1 && chip === '') {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      let newChipArray;

      if (state.editIndex !== -1) {
        if (chip === '') {
          newChipArray = [...field.value];
          newChipArray.splice(state.editIndex, 1);
        } else {
          newChipArray = field.value;
          newChipArray[state.editIndex].text = chip;
        }
      } else if (chip !== '') {
        newChipArray = field.value.concat([{ text: chip, week: currentWeek }]);
      }

      setState({ inputValue: '', editIndex: -1 });

      setFieldValue(field.name, newChipArray);
      setFieldTouched(field.name, true);
      onValueChange(field.name, newChipArray);
    },
    [setFieldValue, setFieldTouched, field, state.editIndex, onValueChange]
  );

  const handleChipDelete = useCallback(
    (_chip: string, index: number) => {
      const newChipArray = [...field.value];
      newChipArray.splice(index, 1);

      setFieldValue(field.name, newChipArray);
      setFieldTouched(field.name, true);
      onValueChange(field.name, newChipArray);
      setState({ inputValue: '', editIndex: -1 });
    },
    [setFieldValue, setFieldTouched, field, onValueChange, setState]
  );

  const handleChipEdit = useCallback(
    (chip: string, index: number) => {
      if (index === state.editIndex) {
        onValueChange(field.name, field.value);
        return setState({ inputValue: '', editIndex: -1 });
      }

      inputRef && inputRef.current && inputRef.current.focus();
      setFieldTouched(field.name, true);
      setState({ inputValue: chip, editIndex: index });
    },
    [state.editIndex, onValueChange, field.name, field.value, setFieldTouched]
  );

  const error = errors[field.name];
  const isTouched = touched[field.name];

  return (
    <>
      <FormLabel
        component={() => (
          <ChipLabel
            text={label}
            amount={field.value ? field.value.length : 0}
            required={required}
            disabled={locked}
          ></ChipLabel>
        )}
        error={isTouched && !!error}
      />
      <StyledInput
        onKeyDown={handleChipAdd}
        onFocus={() => {
          lockField(field.name, true);
        }}
        onBlur={() => {
          lockField(field.name, false);
        }}
        inputRef={inputRef}
        error={isTouched && !!error}
        helperText={isTouched ? error : ''}
        disabled={locked}
        value={state.inputValue}
        onChange={e => setState({ ...state, inputValue: e.target.value })}
      />
      <ChipContainer>
        {field.value && field.value.length
          ? field.value.map((item: WeekListChip, i: number) => (
              <Chip
                text={ChipPrinter(item)}
                onDelete={handleChipDelete}
                key={i}
                index={i}
                onEdit={handleChipEdit}
                isEditMode={state.editIndex === i}
                disabled={locked}
              />
            ))
          : null}
      </ChipContainer>
    </>
  );
};

const WeekListInput: React.FunctionComponent<WeekListInputProps> = inputProps => {
  return (
    <Field name={inputProps.variableName}>
      {(props: FieldProps) => <MaterialInput {...props} {...inputProps} />}
    </Field>
  );
};

export default WeekListInput;
