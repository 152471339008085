import { BasicConfigInputsInputTypeEnum, Weekly, BasicConfig } from '../api';

const inputEmptyValues: { [inputType: string]: unknown } = {
  [BasicConfigInputsInputTypeEnum.Freetext]: '',
  [BasicConfigInputsInputTypeEnum.Number]: null,
  [BasicConfigInputsInputTypeEnum.Weeklist]: [],
  [BasicConfigInputsInputTypeEnum.Image]: [],
  [BasicConfigInputsInputTypeEnum.Event]: []
};

export const getEmptyValueForInput = (inputType: BasicConfigInputsInputTypeEnum) =>
  inputEmptyValues?.[inputType] ?? '';

export const addDefaultData = (
  data: Weekly['data'],
  inputs: BasicConfig['inputs']
): Weekly['data'] => {
  const newData: { [variableName: string]: unknown } = {};
  for (const { inputType, variableName } of inputs) {
    newData[variableName] =
      // @@TODO Can this be specified in the swagger config?
      (data as { [variableName: string]: unknown })?.[variableName] ??
      getEmptyValueForInput(inputType);
  }
  return newData;
};
