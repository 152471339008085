import { AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios';
import io from 'socket.io-client';

import { Configuration, WeeklyApi, ImagesApi, Weekly as WeeklyType } from '../api';

enum EHttpStatus {
  OK = 200,
  UNAUTHORIZED = 401
}

const axiosOptions: AxiosRequestConfig = {
  validateStatus: status =>
    status === EHttpStatus.UNAUTHORIZED || (status >= EHttpStatus.OK && status < 300),
  withCredentials: true
};

const { NODE_ENV, REACT_APP_API_HOST } = process.env;

export const getBasePath = () =>
  NODE_ENV === 'development' ? 'http://localhost:3000' : REACT_APP_API_HOST;

const conf: Configuration = {
  // @@TODO Get from env? Or OpenAPI configuration?
  basePath: getBasePath(),
  baseOptions: axiosOptions
};

const Weekly = new WeeklyApi(conf);
const Upload = new ImagesApi(conf);

type HttpNoResponse = void;

interface HttpDataResponse {
  data: object;
}

interface UnauthorizedResponse extends HttpDataResponse {
  data: {
    authLink: string;
  };
}

const handleUnauthorizedRequest = <
  T extends HttpDataResponse | HttpNoResponse | UnauthorizedResponse
>(
  response: AxiosResponse<T>
) => {
  if (response.status === EHttpStatus.UNAUTHORIZED) {
    const { authLink } = (response.data as UnauthorizedResponse).data;

    if (authLink) {
      const { pathname } = window.location;
      const redirectUrl = `${authLink}?return=${pathname}`;
      window.location.replace(redirectUrl);
    }
  }
};

const responseRequest = async <T extends HttpDataResponse | UnauthorizedResponse>(
  req: AxiosPromise<T>
): Promise<T['data']> => {
  try {
    const response = await req;

    handleUnauthorizedRequest(response);

    return (response.data as HttpDataResponse).data;
  } catch (e) {
    throw e;
  }
};

const noResponseRequest = async <T extends HttpNoResponse | UnauthorizedResponse>(
  req: AxiosPromise<T>
): Promise<HttpNoResponse> => {
  try {
    const response = await req;

    handleUnauthorizedRequest(response);
  } catch (e) {
    throw e;
  }
};

export const getWeekly = (...params: Parameters<WeeklyApi['confOfficeWeeklyWeekLoadGet']>) =>
  responseRequest(Weekly.confOfficeWeeklyWeekLoadGet(...params));

export const saveWeekly = (...params: Parameters<WeeklyApi['confOfficeWeeklyWeekSavePost']>) =>
  noResponseRequest(Weekly.confOfficeWeeklyWeekSavePost(...params));

export const submitWeekly = (...params: Parameters<WeeklyApi['confOfficeWeeklyWeekSubmitPost']>) =>
  noResponseRequest(Weekly.confOfficeWeeklyWeekSubmitPost(...params));

export const generatePreview = (
  ...params: Parameters<WeeklyApi['confOfficeWeeklyWeekPreviewPost']>
) => responseRequest(Weekly.confOfficeWeeklyWeekPreviewPost(...params));

export const uploadImages = (...params: Parameters<ImagesApi['imagesPost']>) =>
  responseRequest(Upload.imagesPost(...params));

export const deleteImage = (...params: Parameters<ImagesApi['imagesFilenameDelete']>) =>
  responseRequest(Upload.imagesFilenameDelete(...params));

// Socket for live-edit
export enum LiveEditMessageType {
  SAVE = 'save',
  SAVE_SUCCESS = 'save-success',
  SAVE_FAILED = 'save-failed',
  LOCK = 'lock-field',
  LOCK_SUCCESS = 'lock-field-success',
  LOCK_FAILED = 'lock-field-failed',
  CONNECTION = 'connection',
  CONNECTION_ERROR = 'connection-failed',
  NEW_FIELD_VALUE = 'new-field-value'
}

export interface LiveEditMessageFieldLocked {
  field: string;
  locked: boolean;
}
export interface LiveEditMessageFieldLockedFailed {
  field: string;
  reason: unknown;
}
export interface LiveEditMessageFieldSaved {
  field: string;
  value: unknown;
}
const options: SocketIOClient.ConnectOpts = {};
export const openSocketConnection = (path: string, weekly: WeeklyType): SocketIOClient.Socket => {
  const socket = io(`${getBasePath()}`, {
    ...options,
    path,
    query: {
      office: weekly.office,
      week: weekly.week,
      year: weekly.year
    }
  });
  return socket;
};
