// tslint:disable
/**
 * Weekly API
 * ..
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mhog@netlight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BasicConfig
 */
export interface BasicConfig {
    /**
     * 
     * @type {string}
     * @memberof BasicConfig
     */
    office: string;
    /**
     * 
     * @type {Array<BasicConfigInputGroups>}
     * @memberof BasicConfig
     */
    inputGroups?: Array<BasicConfigInputGroups>;
    /**
     * 
     * @type {Array<BasicConfigInputs>}
     * @memberof BasicConfig
     */
    inputs: Array<BasicConfigInputs>;
}
/**
 * 
 * @export
 * @interface BasicConfigInputGroups
 */
export interface BasicConfigInputGroups {
    /**
     * 
     * @type {string}
     * @memberof BasicConfigInputGroups
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BasicConfigInputGroups
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof BasicConfigInputGroups
     */
    order: number;
}
/**
 * 
 * @export
 * @interface BasicConfigInputs
 */
export interface BasicConfigInputs {
    /**
     * 
     * @type {string}
     * @memberof BasicConfigInputs
     */
    inputType: BasicConfigInputsInputTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BasicConfigInputs
     */
    required: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicConfigInputs
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof BasicConfigInputs
     */
    variableName: string;
    /**
     * 
     * @type {BasicConfigParams}
     * @memberof BasicConfigInputs
     */
    params?: BasicConfigParams;
}

/**
    * @export
    * @enum {string}
    */
export enum BasicConfigInputsInputTypeEnum {
    Freetext = 'freetext',
    Number = 'number',
    Weeklist = 'weeklist',
    Image = 'image',
    Event = 'event'
}

/**
 * 
 * @export
 * @interface BasicConfigParams
 */
export interface BasicConfigParams {
    /**
     * 
     * @type {string}
     * @memberof BasicConfigParams
     */
    group?: string;
    /**
     * 
     * @type {number}
     * @memberof BasicConfigParams
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface FullConfig
 */
export interface FullConfig {
    /**
     * 
     * @type {string}
     * @memberof FullConfig
     */
    office: string;
    /**
     * 
     * @type {Array<BasicConfigInputGroups>}
     * @memberof FullConfig
     */
    inputGroups?: Array<BasicConfigInputGroups>;
    /**
     * 
     * @type {Array<BasicConfigInputs>}
     * @memberof FullConfig
     */
    inputs: Array<BasicConfigInputs>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FullConfig
     */
    authorizedEmails: Array<string>;
    /**
     * 
     * @type {FullConfigAllOfSlackIntegration}
     * @memberof FullConfig
     */
    slackIntegration: FullConfigAllOfSlackIntegration;
    /**
     * 
     * @type {string}
     * @memberof FullConfig
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof FullConfig
     */
    templateStylesheet: string;
}
/**
 * 
 * @export
 * @interface FullConfigAllOf
 */
export interface FullConfigAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof FullConfigAllOf
     */
    authorizedEmails: Array<string>;
    /**
     * 
     * @type {FullConfigAllOfSlackIntegration}
     * @memberof FullConfigAllOf
     */
    slackIntegration: FullConfigAllOfSlackIntegration;
    /**
     * 
     * @type {string}
     * @memberof FullConfigAllOf
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof FullConfigAllOf
     */
    templateStylesheet: string;
}
/**
 * 
 * @export
 * @interface FullConfigAllOfSlackIntegration
 */
export interface FullConfigAllOfSlackIntegration {
    /**
     * 
     * @type {string}
     * @memberof FullConfigAllOfSlackIntegration
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof FullConfigAllOfSlackIntegration
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof FullConfigAllOfSlackIntegration
     */
    channelId?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject
     */
    images?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {InlineResponse200Data}
     * @memberof InlineResponse200
     */
    data: InlineResponse200Data;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    error: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {InlineResponse2001Data}
     * @memberof InlineResponse2001
     */
    data: InlineResponse2001Data;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    error: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse2001Data
 */
export interface InlineResponse2001Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001Data
     */
    html?: string;
    /**
     * 
     * @type {Weekly}
     * @memberof InlineResponse2001Data
     */
    weekly?: Weekly;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {InlineResponse2002Data}
     * @memberof InlineResponse2002
     */
    data: InlineResponse2002Data;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    error: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse2002Data
 */
export interface InlineResponse2002Data {
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse2002Data
     */
    images?: Array<object>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {InlineResponse2003Data}
     * @memberof InlineResponse2003
     */
    data: InlineResponse2003Data;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    error: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Data
 */
export interface InlineResponse2003Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003Data
     */
    deleted?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200Data
 */
export interface InlineResponse200Data {
    /**
     * 
     * @type {BasicConfig}
     * @memberof InlineResponse200Data
     */
    config?: BasicConfig;
    /**
     * 
     * @type {Weekly}
     * @memberof InlineResponse200Data
     */
    weekly?: Weekly;
}
/**
 * 
 * @export
 * @interface Weekly
 */
export interface Weekly {
    /**
     * 
     * @type {string}
     * @memberof Weekly
     */
    office: string;
    /**
     * 
     * @type {number}
     * @memberof Weekly
     */
    week: number;
    /**
     * 
     * @type {number}
     * @memberof Weekly
     */
    year: number;
    /**
     * 
     * @type {boolean}
     * @memberof Weekly
     */
    submitted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Weekly
     */
    updatedAt?: string;
    /**
     * 
     * @type {object}
     * @memberof Weekly
     */
    data: object;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCheckGet(options: any = {}): RequestArgs {
            const localVarPath = `/auth/check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutPost(options: any = {}): RequestArgs {
            const localVarPath = `/auth/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authWhoamiGet(options: any = {}): RequestArgs {
            const localVarPath = `/auth/whoami`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCheckGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).authCheckGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutPost(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).authLogoutPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authWhoamiGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).authWhoamiGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCheckGet(options?: any) {
            return AuthApiFp(configuration).authCheckGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutPost(options?: any) {
            return AuthApiFp(configuration).authLogoutPost(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authWhoamiGet(options?: any) {
            return AuthApiFp(configuration).authWhoamiGet(options)(axios, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCheckGet(options?: any) {
        return AuthApiFp(this.configuration).authCheckGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogoutPost(options?: any) {
        return AuthApiFp(this.configuration).authLogoutPost(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authWhoamiGet(options?: any) {
        return AuthApiFp(this.configuration).authWhoamiGet(options)(this.axios, this.basePath);
    }

}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confGet(options: any = {}): RequestArgs {
            const localVarPath = `/conf`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} office City name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeGet(office: string, options: any = {}): RequestArgs {
            // verify required parameter 'office' is not null or undefined
            if (office === null || office === undefined) {
                throw new RequiredError('office','Required parameter office was null or undefined when calling confOfficeGet.');
            }
            const localVarPath = `/conf/{office}`
                .replace(`{${"office"}}`, encodeURIComponent(String(office)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} office City name
         * @param {FullConfig} fullConfig Config object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficePost(office: string, fullConfig: FullConfig, options: any = {}): RequestArgs {
            // verify required parameter 'office' is not null or undefined
            if (office === null || office === undefined) {
                throw new RequiredError('office','Required parameter office was null or undefined when calling confOfficePost.');
            }
            // verify required parameter 'fullConfig' is not null or undefined
            if (fullConfig === null || fullConfig === undefined) {
                throw new RequiredError('fullConfig','Required parameter fullConfig was null or undefined when calling confOfficePost.');
            }
            const localVarPath = `/conf/{office}`
                .replace(`{${"office"}}`, encodeURIComponent(String(office)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fullConfig !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fullConfig !== undefined ? fullConfig : {}) : (fullConfig || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FullConfig} fullConfig Config object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confPut(fullConfig: FullConfig, options: any = {}): RequestArgs {
            // verify required parameter 'fullConfig' is not null or undefined
            if (fullConfig === null || fullConfig === undefined) {
                throw new RequiredError('fullConfig','Required parameter fullConfig was null or undefined when calling confPut.');
            }
            const localVarPath = `/conf`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fullConfig !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fullConfig !== undefined ? fullConfig : {}) : (fullConfig || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).confGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} office City name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeGet(office: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).confOfficeGet(office, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} office City name
         * @param {FullConfig} fullConfig Config object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficePost(office: string, fullConfig: FullConfig, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).confOfficePost(office, fullConfig, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {FullConfig} fullConfig Config object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confPut(fullConfig: FullConfig, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ConfigApiAxiosParamCreator(configuration).confPut(fullConfig, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confGet(options?: any) {
            return ConfigApiFp(configuration).confGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} office City name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeGet(office: string, options?: any) {
            return ConfigApiFp(configuration).confOfficeGet(office, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} office City name
         * @param {FullConfig} fullConfig Config object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficePost(office: string, fullConfig: FullConfig, options?: any) {
            return ConfigApiFp(configuration).confOfficePost(office, fullConfig, options)(axios, basePath);
        },
        /**
         * 
         * @param {FullConfig} fullConfig Config object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confPut(fullConfig: FullConfig, options?: any) {
            return ConfigApiFp(configuration).confPut(fullConfig, options)(axios, basePath);
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public confGet(options?: any) {
        return ConfigApiFp(this.configuration).confGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} office City name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public confOfficeGet(office: string, options?: any) {
        return ConfigApiFp(this.configuration).confOfficeGet(office, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} office City name
     * @param {FullConfig} fullConfig Config object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public confOfficePost(office: string, fullConfig: FullConfig, options?: any) {
        return ConfigApiFp(this.configuration).confOfficePost(office, fullConfig, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {FullConfig} fullConfig Config object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public confPut(fullConfig: FullConfig, options?: any) {
        return ConfigApiFp(this.configuration).confPut(fullConfig, options)(this.axios, this.basePath);
    }

}


/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename Filename of file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesFilenameDelete(filename: string, options: any = {}): RequestArgs {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename','Required parameter filename was null or undefined when calling imagesFilenameDelete.');
            }
            const localVarPath = `/images/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename Filename of file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesFilenameGet(filename: string, options: any = {}): RequestArgs {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename','Required parameter filename was null or undefined when calling imagesFilenameGet.');
            }
            const localVarPath = `/images/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost(images?: Array<any>, options: any = {}): RequestArgs {
            const localVarPath = `/images`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (images) {
                images.forEach((image) => {
                    localVarFormParams.append('images[]', image);
                })
                // localVarFormParams.append('images', images.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename Filename of file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesFilenameDelete(filename: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).imagesFilenameDelete(filename, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} filename Filename of file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesFilenameGet(filename: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).imagesFilenameGet(filename, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<any>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost(images?: Array<any>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).imagesPost(images, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} filename Filename of file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesFilenameDelete(filename: string, options?: any) {
            return ImagesApiFp(configuration).imagesFilenameDelete(filename, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} filename Filename of file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesFilenameGet(filename: string, options?: any) {
            return ImagesApiFp(configuration).imagesFilenameGet(filename, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<any>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost(images?: Array<any>, options?: any) {
            return ImagesApiFp(configuration).imagesPost(images, options)(axios, basePath);
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 
     * @param {string} filename Filename of file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesFilenameDelete(filename: string, options?: any) {
        return ImagesApiFp(this.configuration).imagesFilenameDelete(filename, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} filename Filename of file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesFilenameGet(filename: string, options?: any) {
        return ImagesApiFp(this.configuration).imagesFilenameGet(filename, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<any>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesPost(images?: Array<any>, options?: any) {
        return ImagesApiFp(this.configuration).imagesPost(images, options)(this.axios, this.basePath);
    }

}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCheckGet(options: any = {}): RequestArgs {
            const localVarPath = `/status/check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCheckGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = StatusApiAxiosParamCreator(configuration).statusCheckGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusCheckGet(options?: any) {
            return StatusApiFp(configuration).statusCheckGet(options)(axios, basePath);
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public statusCheckGet(options?: any) {
        return StatusApiFp(this.configuration).statusCheckGet(options)(this.axios, this.basePath);
    }

}


/**
 * WeeklyApi - axios parameter creator
 * @export
 */
export const WeeklyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekLoadGet(office: string, week: number, options: any = {}): RequestArgs {
            // verify required parameter 'office' is not null or undefined
            if (office === null || office === undefined) {
                throw new RequiredError('office','Required parameter office was null or undefined when calling confOfficeWeeklyWeekLoadGet.');
            }
            // verify required parameter 'week' is not null or undefined
            if (week === null || week === undefined) {
                throw new RequiredError('week','Required parameter week was null or undefined when calling confOfficeWeeklyWeekLoadGet.');
            }
            const localVarPath = `/conf/{office}/weekly/{week}/load`
                .replace(`{${"office"}}`, encodeURIComponent(String(office)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get preview weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekPreviewPost(office: string, week: number, weekly: Weekly, options: any = {}): RequestArgs {
            // verify required parameter 'office' is not null or undefined
            if (office === null || office === undefined) {
                throw new RequiredError('office','Required parameter office was null or undefined when calling confOfficeWeeklyWeekPreviewPost.');
            }
            // verify required parameter 'week' is not null or undefined
            if (week === null || week === undefined) {
                throw new RequiredError('week','Required parameter week was null or undefined when calling confOfficeWeeklyWeekPreviewPost.');
            }
            // verify required parameter 'weekly' is not null or undefined
            if (weekly === null || weekly === undefined) {
                throw new RequiredError('weekly','Required parameter weekly was null or undefined when calling confOfficeWeeklyWeekPreviewPost.');
            }
            const localVarPath = `/conf/{office}/weekly/{week}/preview`
                .replace(`{${"office"}}`, encodeURIComponent(String(office)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof weekly !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(weekly !== undefined ? weekly : {}) : (weekly || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekSavePost(office: string, week: number, weekly: Weekly, options: any = {}): RequestArgs {
            // verify required parameter 'office' is not null or undefined
            if (office === null || office === undefined) {
                throw new RequiredError('office','Required parameter office was null or undefined when calling confOfficeWeeklyWeekSavePost.');
            }
            // verify required parameter 'week' is not null or undefined
            if (week === null || week === undefined) {
                throw new RequiredError('week','Required parameter week was null or undefined when calling confOfficeWeeklyWeekSavePost.');
            }
            // verify required parameter 'weekly' is not null or undefined
            if (weekly === null || weekly === undefined) {
                throw new RequiredError('weekly','Required parameter weekly was null or undefined when calling confOfficeWeeklyWeekSavePost.');
            }
            const localVarPath = `/conf/{office}/weekly/{week}/save`
                .replace(`{${"office"}}`, encodeURIComponent(String(office)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof weekly !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(weekly !== undefined ? weekly : {}) : (weekly || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Subnut weekly for week and office to Slack
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekSubmitPost(office: string, week: number, weekly: Weekly, options: any = {}): RequestArgs {
            // verify required parameter 'office' is not null or undefined
            if (office === null || office === undefined) {
                throw new RequiredError('office','Required parameter office was null or undefined when calling confOfficeWeeklyWeekSubmitPost.');
            }
            // verify required parameter 'week' is not null or undefined
            if (week === null || week === undefined) {
                throw new RequiredError('week','Required parameter week was null or undefined when calling confOfficeWeeklyWeekSubmitPost.');
            }
            // verify required parameter 'weekly' is not null or undefined
            if (weekly === null || weekly === undefined) {
                throw new RequiredError('weekly','Required parameter weekly was null or undefined when calling confOfficeWeeklyWeekSubmitPost.');
            }
            const localVarPath = `/conf/{office}/weekly/{week}/submit`
                .replace(`{${"office"}}`, encodeURIComponent(String(office)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof weekly !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(weekly !== undefined ? weekly : {}) : (weekly || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeeklyApi - functional programming interface
 * @export
 */
export const WeeklyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Load weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekLoadGet(office: string, week: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
            const localVarAxiosArgs = WeeklyApiAxiosParamCreator(configuration).confOfficeWeeklyWeekLoadGet(office, week, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get preview weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekPreviewPost(office: string, week: number, weekly: Weekly, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001> {
            const localVarAxiosArgs = WeeklyApiAxiosParamCreator(configuration).confOfficeWeeklyWeekPreviewPost(office, week, weekly, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Save weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekSavePost(office: string, week: number, weekly: Weekly, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WeeklyApiAxiosParamCreator(configuration).confOfficeWeeklyWeekSavePost(office, week, weekly, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Subnut weekly for week and office to Slack
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekSubmitPost(office: string, week: number, weekly: Weekly, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WeeklyApiAxiosParamCreator(configuration).confOfficeWeeklyWeekSubmitPost(office, week, weekly, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WeeklyApi - factory interface
 * @export
 */
export const WeeklyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Load weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekLoadGet(office: string, week: number, options?: any) {
            return WeeklyApiFp(configuration).confOfficeWeeklyWeekLoadGet(office, week, options)(axios, basePath);
        },
        /**
         * Get preview weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekPreviewPost(office: string, week: number, weekly: Weekly, options?: any) {
            return WeeklyApiFp(configuration).confOfficeWeeklyWeekPreviewPost(office, week, weekly, options)(axios, basePath);
        },
        /**
         * Save weekly for week and office
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekSavePost(office: string, week: number, weekly: Weekly, options?: any) {
            return WeeklyApiFp(configuration).confOfficeWeeklyWeekSavePost(office, week, weekly, options)(axios, basePath);
        },
        /**
         * Subnut weekly for week and office to Slack
         * @param {string} office Name of office
         * @param {number} week Week number
         * @param {Weekly} weekly Weekly object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confOfficeWeeklyWeekSubmitPost(office: string, week: number, weekly: Weekly, options?: any) {
            return WeeklyApiFp(configuration).confOfficeWeeklyWeekSubmitPost(office, week, weekly, options)(axios, basePath);
        },
    };
};

/**
 * WeeklyApi - object-oriented interface
 * @export
 * @class WeeklyApi
 * @extends {BaseAPI}
 */
export class WeeklyApi extends BaseAPI {
    /**
     * Load weekly for week and office
     * @param {string} office Name of office
     * @param {number} week Week number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeeklyApi
     */
    public confOfficeWeeklyWeekLoadGet(office: string, week: number, options?: any) {
        return WeeklyApiFp(this.configuration).confOfficeWeeklyWeekLoadGet(office, week, options)(this.axios, this.basePath);
    }

    /**
     * Get preview weekly for week and office
     * @param {string} office Name of office
     * @param {number} week Week number
     * @param {Weekly} weekly Weekly object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeeklyApi
     */
    public confOfficeWeeklyWeekPreviewPost(office: string, week: number, weekly: Weekly, options?: any) {
        return WeeklyApiFp(this.configuration).confOfficeWeeklyWeekPreviewPost(office, week, weekly, options)(this.axios, this.basePath);
    }

    /**
     * Save weekly for week and office
     * @param {string} office Name of office
     * @param {number} week Week number
     * @param {Weekly} weekly Weekly object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeeklyApi
     */
    public confOfficeWeeklyWeekSavePost(office: string, week: number, weekly: Weekly, options?: any) {
        return WeeklyApiFp(this.configuration).confOfficeWeeklyWeekSavePost(office, week, weekly, options)(this.axios, this.basePath);
    }

    /**
     * Subnut weekly for week and office to Slack
     * @param {string} office Name of office
     * @param {number} week Week number
     * @param {Weekly} weekly Weekly object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeeklyApi
     */
    public confOfficeWeeklyWeekSubmitPost(office: string, week: number, weekly: Weekly, options?: any) {
        return WeeklyApiFp(this.configuration).confOfficeWeeklyWeekSubmitPost(office, week, weekly, options)(this.axios, this.basePath);
    }

}


