import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled/macro';
import css from '@emotion/css';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  KeyboardTimePicker,
  KeyboardTimePickerProps
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DeleteIcon from '@material-ui/icons/Delete';
import { EventObject } from '.';
import LabelType from '../LabelType';
import StyledIconButton from '../StyledIconButton';
import StyledCard, { StyledCardProps } from '../StyledCard';

interface EventCardProps {
  index: number;
  data: EventObject;
  disabled: boolean;
  onLock: (disabled: boolean) => void;
  onEventDelete: (index: number) => void;
  onEventChange: (event: EventObject, index: number) => void;
}

const StyledEventCard = styled(StyledCard)<StyledCardProps>`
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 0.5rem;
`;

const DefaultEventInputStyle = css`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)<KeyboardDatePickerProps>`
  ${DefaultEventInputStyle}
  padding-bottom: 0.5rem;
`;

const StyledKeyboardTimePicker = styled(KeyboardTimePicker)<KeyboardTimePickerProps>`
  ${DefaultEventInputStyle}
  padding-bottom: 0.5rem;
`;

const StyledTextField = styled(TextField)<TextFieldProps>`
  ${DefaultEventInputStyle}
`;

const DateFieldDescriptionType = styled.span`
  ${DefaultEventInputStyle}
`;

const TIME_FORMAT: string = 'HH:mm';
const DATE_FORMAT: string = 'YYYY-MM-DD';

const EventCard: React.FunctionComponent<EventCardProps> = ({
  index,
  data,
  onEventDelete,
  onEventChange,
  onLock,
  disabled
}) => {
  const [currentDate, setCurrentDate] = useState<Moment | null>(null);
  const [currentTime, setCurrentTime] = useState<Moment | null>(null);
  const [currentDescription, setCurrentDescription] = useState<string>('');

  useEffect(() => {
    setCurrentDate(data.date ? moment(data.date, DATE_FORMAT) : null);
    setCurrentTime(data.time ? moment(data.time, TIME_FORMAT) : null);
    setCurrentDescription(data.description);
  }, [
    data.date,
    data.time,
    data.description,
    setCurrentDate,
    setCurrentTime,
    setCurrentDescription
  ]);

  const handleEventChange = useCallback(
    (date: Moment | null, time: Moment | null, description: string) => {
      const newEventObject: EventObject = {
        date: date && date.isValid() ? date.format(DATE_FORMAT) : '',
        time: time && time.isValid() ? time.format(TIME_FORMAT) : '',
        description: description
      };
      onEventChange(newEventObject, index);
    },
    [index, onEventChange]
  );

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      const newDate = date ? moment(date) : null;
      setCurrentDate(newDate);
      handleEventChange(newDate, currentTime, currentDescription);
    },
    [handleEventChange, currentTime, currentDescription]
  );

  const handleTimeChange = useCallback(
    (time: MaterialUiPickersDate) => {
      const newTime = time ? moment(time) : null;
      setCurrentTime(newTime);
      handleEventChange(currentDate, newTime, currentDescription);
    },
    [handleEventChange, currentDate, currentDescription]
  );

  const handleDescriptionChange = useCallback(
    (description: string) => {
      setCurrentDescription(description);
      handleEventChange(currentDate, currentTime, description);
    },
    [handleEventChange, currentDate, currentTime]
  );

  return (
    <StyledEventCard disabled={disabled}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StyledKeyboardDatePicker
          disableToolbar
          required={true}
          variant="inline"
          autoOk={true}
          format="DD/MM/YYYY"
          label={
            <>
              <DateFieldDescriptionType>Date</DateFieldDescriptionType>
              <LabelType>(Day/Month/Year)</LabelType>
            </>
          }
          value={currentDate}
          onChange={date => handleDateChange(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          disabled={disabled}
          onOpen={() => {
            onLock(true);
          }}
          onClose={() => {
            onLock(false);
          }}
          onFocus={() => {
            onLock(true);
          }}
          onBlur={() => {
            onLock(false);
          }}
        />
        <StyledKeyboardTimePicker
          variant="inline"
          autoOk={true}
          required={true}
          label={
            <>
              <DateFieldDescriptionType>Time</DateFieldDescriptionType>
              <LabelType>(hour:minute)</LabelType>
            </>
          }
          ampm={false}
          format="HH:mm"
          value={currentTime}
          onChange={time => handleTimeChange(time)}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
          disabled={disabled}
          onOpen={() => {
            onLock(true);
          }}
          onClose={() => {
            onLock(false);
          }}
          onFocus={() => {
            onLock(true);
          }}
          onBlur={() => {
            onLock(false);
          }}
        />
      </MuiPickersUtilsProvider>
      <StyledTextField
        required={true}
        label={
          <>
            <DateFieldDescriptionType>Description</DateFieldDescriptionType>
            <LabelType>(text)</LabelType>
          </>
        }
        value={currentDescription}
        multiline
        onChange={e => {
          const { value } = e.target;
          handleDescriptionChange(value);
        }}
        fullWidth={true}
        disabled={disabled}
        onFocus={() => {
          onLock(true);
        }}
        onBlur={() => {
          onLock(false);
        }}
      />

      <StyledIconButton aria-label="delete event" onClick={() => onEventDelete(index)} size="small">
        <DeleteIcon fontSize="small" />
      </StyledIconButton>
    </StyledEventCard>
  );
};

export default EventCard;
