import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastProvider } from 'react-toast-notifications';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import WeeklyFormPage from './views/WeeklyForm';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Proxima Nova Regular'
  }
});

const App: React.FunctionComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <CssBaseline />
        <Router>
          <Switch>
            <Route path="/:office/:week?">
              <WeeklyFormPage />
            </Route>
          </Switch>
        </Router>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
