import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Form, Formik, FormikValues, FormikHelpers } from 'formik';
import { useToasts } from 'react-toast-notifications';
import styled from '@emotion/styled/macro';
import { debounce } from 'lodash';

import { BasicConfig, Weekly, BasicConfigInputGroups, BasicConfigInputs } from '../../api';
import WeeklyFormInput from './components/inputs/WeeklyFormInput';
import { addDefaultData } from '../../utilities/WeeklyHelper';
import SubmitButton from './components/buttons/SubmitButton';
import FieldChangeWatcher from './components/common/FieldChangeWatcher';
import { LiveEditMessageFieldLocked } from '../../utilities/WebApi';

export interface WeeklyFormProps {
  config: BasicConfig;
  weekly: Weekly;
  fieldSocket?: SocketIOClient.Socket | null;
  onPreview: (weekly: Weekly) => void;
  onSubmit: (newWeekly: Weekly) => void;
  onLockField: (field: string, setLocked: boolean) => void;
  onAutoSave: (field: string, value: unknown) => void;
}

interface LockedFields {
  [field: string]: boolean;
}

const StyledForm = styled(Form)`
  padding: 0 4rem;
  position: relative;
  width: 36rem;
  padding: 0.5rem 1rem;
`;

const Group = styled.div``;
const GroupLabel = styled.h3`
  font-weight: 600;
`;
const GroupInputContainer = styled.div`
  padding-left: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-betweem;

  > button {
    margin: 0 0.2rem;
  }

  button:last-child {
    margin-left: 1rem;
  }
`;

const OfficeHeader = styled.h1`
  margin: 0;
  line-height: 0.9;
`;

const WeekHeader = styled.span`
  font-size: 1.3em;
`;

const FormHeaderContainer = styled.div`
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fafafa;
  border-bottom: 2px solid #000000;
  z-index: 100;
`;

const orderGroupSorter = (a: BasicConfigInputGroups, b: BasicConfigInputGroups) =>
  a.order - b.order;

const orderInputSorter = (a: BasicConfigInputs, b: BasicConfigInputs) =>
  // @ts-ignore
  a.params && b.params ? a.params.order - b.params.order : 0;

const WeeklyForm: React.FunctionComponent<WeeklyFormProps> = React.memo(
  ({ config, onPreview, onSubmit, onAutoSave, onLockField, weekly, fieldSocket }) => {
    if (!config || !weekly) return null;

    const { addToast } = useToasts();
    const [lockedFields, setLockedFields] = useState<LockedFields>({});

    const setFieldLocked = useCallback((newLockedFields: LiveEditMessageFieldLocked[]) => {
      let newValues: LockedFields = {};
      if (!newLockedFields.length) {
        return;
      }
      newLockedFields.forEach(lockedField => {
        newValues[lockedField.field] = lockedField.locked;
      });
      setLockedFields(oldValues => ({ ...oldValues, ...newValues }));
    }, []);

    const handleSubmit = useCallback(
      async (values: FormikValues, { setSubmitting }: FormikHelpers<FormikValues>) => {
        const newWeekly: Weekly = { ...weekly, data: values };

        try {
          await onSubmit(newWeekly);
        } catch (e) {
          addToast(`Could not submit weekly.`, { appearance: 'error', autoDismiss: true });
        }
        setSubmitting(false);
      },
      [onSubmit, addToast, weekly]
    );

    const initialValues: FormikValues = useMemo(() => addDefaultData(weekly.data, config.inputs), [
      config.inputs,
      weekly.data
    ]);

    const previewForm = useCallback(debounce(onPreview, 1000, { trailing: true, leading: false }), [
      onPreview
    ]);
    const autoSave = useCallback(debounce(onAutoSave, 1000, { trailing: true, leading: false }), [
      onAutoSave
    ]);

    useEffect(() => {
      // request preview on first load
      previewForm({ ...weekly, data: initialValues });
    }, [initialValues, weekly, previewForm]);

    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, submitForm, setFieldValue }) => (
          <StyledForm data-testid="weekly-form">
            {fieldSocket ? (
              <FieldChangeWatcher
                onFieldValue={(field: string, value: unknown) => {
                  setFieldValue(field, value);
                  previewForm({ ...weekly, data: { ...values, [field]: value } });
                }}
                onFieldLocked={setFieldLocked}
                fieldChangeSubscriber={fieldSocket}
              />
            ) : null}
            <FormHeaderContainer>
              <div>
                <OfficeHeader>{config.office}</OfficeHeader>
                <WeekHeader>week {weekly.week}</WeekHeader>
              </div>
              <ButtonContainer>
                <SubmitButton onSubmit={submitForm} disabled={isSubmitting || !!weekly.submitted} />
              </ButtonContainer>
            </FormHeaderContainer>
            {config.inputGroups
              ? config.inputGroups.sort(orderGroupSorter).map(group => (
                  <Group key={group.id}>
                    <GroupLabel>{group.label}</GroupLabel>
                    <GroupInputContainer>
                      {config.inputs
                        .filter(input => input.params && input.params.group === group.id)
                        .map(input => (
                          <WeeklyFormInput
                            {...input}
                            key={input.variableName}
                            lockField={onLockField}
                            locked={!!lockedFields[input.variableName]}
                            onValueChange={(field: string, value: unknown) => {
                              autoSave(field, value);
                              previewForm({ ...weekly, data: { ...values, [field]: value } });
                            }}
                          />
                        ))}
                    </GroupInputContainer>
                  </Group>
                ))
              : config.inputs.sort(orderInputSorter).map(input => (
                  <WeeklyFormInput
                    {...input}
                    key={input.variableName}
                    lockField={onLockField}
                    locked={!!lockedFields[input.variableName]}
                    onValueChange={(field: string, value: unknown) => {
                      autoSave(field, value);
                      previewForm({ ...weekly, data: { ...values, [field]: value } });
                    }}
                  />
                ))}
          </StyledForm>
        )}
      </Formik>
    );
  }
);

export default WeeklyForm;
