import React from 'react';

import { BasicConfigInputs, BasicConfigInputsInputTypeEnum } from '../../../../api';
import TextInput, { TextInputProps } from './TextInput';
import NumberInput, { NumberInputProps } from './NumberInput';
import WeekListInput, { WeekListInputProps } from './WeekListInput';
import ImageInput, { ImageInputProps } from './ImageInput';
import EventInput, { EventInputProps } from './EventInput';

import styled from '@emotion/styled/macro';

export interface WeeklyFormInputProps extends BasicConfigInputs {
  locked: boolean;
  onValueChange: (field: string, value: unknown) => void;
  lockField: (field: string, setLocked: boolean) => void;
}

const InputContainer = styled.div`
  padding-bottom: 20px;
`;

const getInputComponent = (props: WeeklyFormInputProps) => {
  switch (props.inputType) {
    case BasicConfigInputsInputTypeEnum.Freetext: {
      return <TextInput {...(props as TextInputProps)} />;
    }
    case BasicConfigInputsInputTypeEnum.Number: {
      return <NumberInput {...(props as NumberInputProps)} />;
    }
    case BasicConfigInputsInputTypeEnum.Weeklist: {
      return <WeekListInput {...(props as WeekListInputProps)} />;
    }
    case BasicConfigInputsInputTypeEnum.Image: {
      return <ImageInput {...(props as ImageInputProps)} />;
    }
    case BasicConfigInputsInputTypeEnum.Event: {
      return <EventInput {...(props as EventInputProps)} />;
    }
    default: {
      return null;
    }
  }
};

const WeeklyFormInput: React.FunctionComponent<WeeklyFormInputProps> = props => {
  const inputComponent = getInputComponent(props);

  return <InputContainer>{inputComponent}</InputContainer>;
};

export default WeeklyFormInput;
