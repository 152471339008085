import React from 'react';
import { Field, FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import styled from '@emotion/styled/macro';

import { BasicConfigInputsInputTypeEnum } from '../../../../api';
import { validateRequired } from '../../utilities/formUtils';
import LabelType from './LabelType';
import { WeeklyFormInputProps } from './WeeklyFormInput';

export interface TextInputProps extends WeeklyFormInputProps {
  inputType: BasicConfigInputsInputTypeEnum.Freetext;
}

// Placeholder - just an example of how to combine MUI + emotion + Formik
const StyledField = styled(TextField)<TextFieldProps>`
  width: 100%;
`;

const MaterialInput: React.FunctionComponent<FieldProps & TextInputProps> = ({
  field,
  form: { errors, touched },
  label,
  required,
  onValueChange,
  lockField,
  locked
}) => {
  const error = errors[field.name];
  const isTouched = touched[field.name];
  return (
    <StyledField
      {...field}
      onFocus={() => {
        lockField(field.name, true);
      }}
      onBlur={e => {
        lockField(field.name, false);
        field.onBlur(e);
      }}
      onChange={e => {
        const value = e.target.value;
        onValueChange(field.name, value);
        field.onChange(e);
      }}
      required={required}
      multiline={true}
      type="text"
      disabled={locked}
      error={isTouched && !!error}
      helperText={isTouched ? error : ''}
      label={
        <span data-testid={label}>
          {label}
          <LabelType>(text)</LabelType>
        </span>
      }
    />
  );
};

const TextInput: React.FunctionComponent<TextInputProps> = ({ variableName, ...inputProps }) => {
  return (
    <Field
      name={variableName}
      component={MaterialInput}
      validate={inputProps.required ? validateRequired : () => null}
      {...inputProps}
    ></Field>
  );
};

export default TextInput;
