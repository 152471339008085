const validateRequired = (value: string | number | Array<string>) => {
  if (!value && value !== 0) {
    return 'Required';
  }
};

const validateRequiredArray = (value: Array<string>) => {
  if (value.length === 0) {
    return 'Required';
  }
};

const validateWeekNumber = (week: number) => week > 0 && week < 53;

export { validateRequired, validateRequiredArray, validateWeekNumber };
