import { FunctionComponent, memo, useEffect } from 'react';
import {
  LiveEditMessageType,
  LiveEditMessageFieldSaved,
  LiveEditMessageFieldLocked,
  LiveEditMessageFieldLockedFailed
} from '../../../../utilities/WebApi';

interface FieldChangeWatcherProps {
  fieldChangeSubscriber: SocketIOClient.Socket;
  onFieldValue: (field: string, value: unknown) => any;
  onFieldLocked: (lockedFields: LiveEditMessageFieldLocked[]) => void;
}
const FieldChangeWatcher: FunctionComponent<FieldChangeWatcherProps> = memo(
  ({ fieldChangeSubscriber, onFieldLocked, onFieldValue }) => {
    // Incoming value handler
    useEffect(() => {
      const callback = ({ field, value }: LiveEditMessageFieldSaved) => {
        onFieldValue(field, value);
      };

      fieldChangeSubscriber.on(LiveEditMessageType.NEW_FIELD_VALUE, callback);
      return () => {
        fieldChangeSubscriber.off(LiveEditMessageType.NEW_FIELD_VALUE, callback);
      };
    }, [fieldChangeSubscriber, onFieldValue]);

    // Incoming locked field handler
    useEffect(() => {
      const callback = (lockedFields: LiveEditMessageFieldLocked[]) => {
        onFieldLocked(lockedFields);
      };

      fieldChangeSubscriber.on(LiveEditMessageType.LOCK_SUCCESS, callback);
      return () => {
        fieldChangeSubscriber.off(LiveEditMessageType.LOCK_SUCCESS, callback);
      };
    }, [fieldChangeSubscriber, onFieldLocked]);

    useEffect(() => {
      const callback = ({ field }: LiveEditMessageFieldLockedFailed) => {
        onFieldLocked([{ field, locked: false }]);
      };

      fieldChangeSubscriber.on(LiveEditMessageType.LOCK_FAILED, callback);
      return () => {
        fieldChangeSubscriber.off(LiveEditMessageType.LOCK_FAILED, callback);
      };
    }, [fieldChangeSubscriber, onFieldLocked]);
    return null;
  }
);

export default FieldChangeWatcher;
