import React, { FunctionComponent } from 'react';
import MaterialUiChip from '@material-ui/core/Chip';
import styled from '@emotion/styled/macro';

interface ChipProps {
  text: string;
  index: number;
  onDelete: (val: string, index: number) => void;
  onEdit?: (val: string, index: number) => void;
  isEditMode: boolean;
  disabled: boolean;
}

const StyledChip = styled(MaterialUiChip)`
  margin: 0.2rem;
`;

const Chip: FunctionComponent<ChipProps> = ({
  text,
  onEdit,
  index,
  disabled,
  onDelete,
  isEditMode
}) => (
  <StyledChip
    label={text}
    onClick={() => {
      onEdit && onEdit(text, index);
    }}
    onDelete={() => onDelete(text, index)}
    color={isEditMode ? 'primary' : 'default'}
    disabled={disabled}
  />
);

export default Chip;
