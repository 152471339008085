import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled/macro';
import LabelType from '../LabelType';

interface ChipProps {
  text: string;
  amount: number;
  required: boolean;
  disabled: boolean;
}

const Circle = styled.span<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#7473bd' : 'gray')};
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  display: inline-block;
  text-align: center;
  line-height: 1rem;
  font-size: 0.7em;
  color: white;
  margin-left: 0.2rem;
`;

const Container = styled.span<{ disabled: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ disabled }) => (!disabled ? '1' : '0.5')};
`;

const Chip: FunctionComponent<ChipProps> = ({ text, amount, required, disabled }) => (
  <Container disabled={disabled} data-testid={text}>
    <span>{text}</span>
    <LabelType>(list)</LabelType>
    <Circle active={amount > 0 && !disabled}>{amount}</Circle>
    {required && <span>*</span>}
  </Container>
);

export default Chip;
