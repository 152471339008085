import Button from '@material-ui/core/Button';
import styled from '@emotion/styled/macro';

const BasicButton = styled(Button)`
  background-color: #a6a2dc;
  color: white;
  border-radius: 2rem;

  &:hover {
    background-color: #565c98;
  }
`;

export default BasicButton;
