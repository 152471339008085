import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/sv';
import { useToasts } from 'react-toast-notifications';
import styled from '@emotion/styled/macro';

import { BasicConfig, Weekly } from '../../api';
import { validateWeekNumber } from './utilities/formUtils';
import {
  getWeekly,
  generatePreview,
  openSocketConnection,
  LiveEditMessageType
} from '../../utilities/WebApi';
import { submitWeekly } from '../../utilities/WebApi';
import WeeklyForm from './WeeklyForm';
import WeeklyPreview from './WeeklyPreview';

import Boids from './components/images/boids';

interface WeeklyFormPageProps {}

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3rem;
`;

const FormContainer = styled.div`
  background-color: rgba(250, 250, 250, 0.7);
`;

const PreviewContainer = styled.div`
  // This container forces the sticky to work asd expected.
`;

const WeeklyFormPage: React.FunctionComponent<WeeklyFormPageProps> = () => {
  const [config, setConfig] = useState<BasicConfig | null>(null);
  const [weekly, setWeekly] = useState<Weekly | null>(null);
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);

  const { addToast } = useToasts();

  const { office: urlOffice, week: urlWeek } = useParams();

  const autoSaveField = useCallback(
    (field: string, value: unknown): void => {
      socket?.emit(LiveEditMessageType.SAVE, {
        office: weekly?.office,
        week: weekly?.week,
        year: weekly?.year,
        field,
        value
      });
    },
    [socket, weekly]
  );

  const lockField = useCallback(
    (field: string, setLocked: boolean): void => {
      socket?.emit(LiveEditMessageType.LOCK, {
        office: weekly?.office,
        week: weekly?.week,
        year: weekly?.year,
        field,
        setLocked
      });
    },
    [socket, weekly]
  );

  const getPreview = useCallback(
    async (previewWeekly: Weekly) => {
      try {
        const { week, office } = previewWeekly;
        setPreviewLoading(true);
        const { html } = await generatePreview(
          `${office[0].toUpperCase()}${office.substring(1).toLowerCase()}`,
          week,
          previewWeekly
        );

        html && setPreviewHtml(html);
      } catch (e) {
        setPreviewLoading(false);
        addToast(`Could not generate preview.`, { appearance: 'error', autoDismiss: true });
      } finally {
        setPreviewLoading(false);
      }
    },
    [setPreviewLoading, setPreviewHtml, addToast]
  );

  const SubmitWeekly = useCallback(
    async (newWeekly: Weekly) => {
      try {
        if (!weekly || !newWeekly) {
          return;
        }

        if (
          window.confirm &&
          !window.confirm(
            'Are you sure you want to send this to slack? If you do, you cannot change it later 🤓'
          )
        ) {
          return;
        }

        const { week, office } = weekly;
        await submitWeekly(office, week, newWeekly);
        setWeekly({ ...weekly, submitted: true });
        addToast(`Weekly sent!`, { appearance: 'success', autoDismiss: true });
      } catch (e) {
        addToast(`Could not send weekly.`, { appearance: 'error', autoDismiss: true });
      }
    },
    [weekly, setWeekly, addToast]
  );

  useEffect(() => {
    if (!socket && weekly) {
      const newSocket = openSocketConnection('/live-edit', weekly);
      setSocket(newSocket);
    }

    return function socketCleanUp() {
      socket?.close();
    };
  }, [weekly, socket]);

  useEffect(() => {
    const fetchWeekly = async () => {
      if (!urlOffice) {
        return;
      }

      const capitalisedOffice = `${urlOffice[0].toUpperCase()}${urlOffice
        .substring(1)
        .toLowerCase()}`;
      let weekNumber = moment()
        .locale('sv')
        .week();
      try {
        const parsedWeekNumber = parseInt(urlWeek || '', 10);
        weekNumber = validateWeekNumber(parsedWeekNumber) ? parsedWeekNumber : weekNumber;
      } catch (e) {}

      try {
        const { config: configResponse, weekly: weeklyResponse } = await getWeekly(
          capitalisedOffice,
          weekNumber
        );

        if (!configResponse || !weeklyResponse) {
          return;
        }
        setConfig(configResponse);
        setWeekly(weeklyResponse);
      } catch (e) {
        addToast(`Could not fetch Weekly for ${capitalisedOffice}, week ${weekNumber}.`, {
          appearance: 'error',
          autoDismiss: true
        });
      }
    };

    fetchWeekly();
  }, [addToast, urlOffice, urlWeek]);

  if (!config || !weekly) {
    return null;
  }

  return (
    <PageContainer>
      <FormContainer>
        <WeeklyForm
          config={config}
          onPreview={getPreview}
          onAutoSave={autoSaveField}
          weekly={weekly}
          fieldSocket={socket}
          onSubmit={SubmitWeekly}
          onLockField={lockField}
        />
      </FormContainer>
      <PreviewContainer>
        <WeeklyPreview previewHtml={previewHtml} loading={previewLoading} />
      </PreviewContainer>
      <Boids />
    </PageContainer>
  );
};

export default WeeklyFormPage;
