import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled/macro';
import Button from '@material-ui/core/Button';
import { ImageObject } from '.';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';
import HoverableContainer, { HoverableContainerRenderProps } from './HoverableContainer';
import { getBasePath } from '../../../../../utilities/WebApi';

interface EditDialogProps {
  data: ImageObject;
  isOpen: boolean;
  onDone: (file: FileList) => void;
  onClose: () => void;
}

const StyledImage = styled.img`
  min-width: 28rem;
  min-height: 20rem;
  max-width: 30rem;
  max-height: 22rem;
  background-color: #cecdcd;
  display: inline-block;
  text-align: center;
  line-height: 15rem;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Cover = styled.div`
  background-color: black;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoText = styled.span`
  font-weight: bold;
  font-size: 1em;
  text-transform: capitalize;
`;

const EditDialog: React.FunctionComponent<EditDialogProps> = ({
  data,
  onDone,
  onClose,
  isOpen
}) => {
  const [newFile, setNewFile] = useState<FileList | null>(null);
  const [newUrl, setNewUrl] = useState<string | ArrayBuffer | null>(null);

  const onNewImage = useCallback(
    (files: FileList | null) => {
      if (!files) {
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onloadend = () => {
        if (reader && reader.result) setNewUrl(reader.result);
      };
    },
    [setNewUrl]
  );

  const handleClose = useCallback(() => {
    setNewUrl(null);
    setNewFile(null);
    onClose();
  }, [setNewFile, setNewUrl, onClose]);
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit image</DialogTitle>
      <DialogContent>
        <HoverableContainer>
          {({ targetClass }: HoverableContainerRenderProps) => (
            <>
              <HiddenInput
                id="editImage"
                accept="image/*"
                type="file"
                name="images"
                onChange={e => {
                  onNewImage(e.currentTarget.files);
                  setNewFile(e.currentTarget.files);
                }}
              />
              <label htmlFor="editImage">
                <Cover className={targetClass}>
                  <EditIcon />
                  <InfoText>change image</InfoText>
                </Cover>
              </label>
              <StyledImage
                // @ts-ignore - types for images do not accept ArrayBuffer even though it works
                src={newUrl ? newUrl : `${getBasePath()}/images/${data.name}`}
                alt="Image cannot be displayed :("
              />
            </>
          )}
        </HoverableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            newFile && onDone(newFile);
            setNewUrl(null);
            setNewFile(null);
          }}
          color="primary"
          disabled={!newFile || !newUrl}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
