import styled from '@emotion/styled/macro';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';

const StyledIconButton = styled(IconButton)<IconButtonProps>`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.4em;
`;

export default StyledIconButton;
